import React, { useState, useEffect } from "react";
import {  Upload, notification } from "antd";
import {  } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import eventApi from "../../../api/EventApi";
import speakerApi from "../../../api/SpeakerApi";
import utilApi from "../../../api/UtilApi";
import { TextField, Button } from "@mui/material";
import SpeakerDrawer from "../../event-edits/SpeakerDrawer";
import style from "../../event-edits/style.css";

import { createTheme, ThemeProvider } from '@mui/material/styles';
const customTheme = createTheme({
    palette: {
        primary: {
            main: '#2D9687',
        },
    },
});

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const EventDetailsTab = ({ setDisplayEventView, eventData, speakersData }) => {
    const [attendees, setAttendees] = useState(null);
    const [eventId, setEventId] = useState(eventData?.id || "");
    const [previewImage, setPreviewImage] = useState(eventData?.eventImage || "");
    const [eventName, setEventName] = useState(eventData?.eventName || "");
    const [eventLocation, setEventLocation] = useState(eventData?.eventLocation || "");
    const [description, setDescription] = useState(eventData?.description || "");
    const [startDate, setStartDate] = useState(eventData?.startDate ? new Date(eventData.startDate * 1000) : null);
    const [endDate, setEndDate] = useState(eventData?.endDate ? new Date(eventData.endDate * 1000) : null);
    const [latitude, setLatitude] = useState(eventData?.latitude || "-26.109");
    const [longitude, setLongitude] = useState(eventData?.longitude || "28.052");
    const [eventStatus, setEventStatus] = useState(eventData?.eventStatus);
    const [eventBookmarks, setEventBookmarks] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [speakersCount, setSpeakersCount] = useState(speakersData?.length);
    const [allEvents, setAllEvents] = useState([]);
    useEffect(() => {
        setSpeakersCount(speakersData?.length);
    }, [speakersData]);

    useEffect(() => {
        setFileList([{ url: eventData?.eventImage }]);
    }, [eventData]);

    const handlePreview = async (file) => {
        const isSupportedFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp';
        if (!isSupportedFormat) {
            notification.error({
                message: 'Unsupported File Format',
                description: 'You can only upload JPEG/JPG/PNG/WEBP files.',
            });
            return;
        }
        
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        const baseString = file.preview.split(",")[1];
        const imageUrl = await utilApi.convertBase64ToPng(baseString);
        if (imageUrl) {
            notification.success({
                message: 'Image Uploaded',
                description: 'Image uploaded successfully!',
            });
            setPreviewImage(imageUrl);
        }
    };

    const handleImageChange = async ({ fileList }) => {
        setFileList(fileList);
        if (fileList.length > 0) {
            const file = fileList[fileList.length - 1];
            handlePreview(file);
        }
    };

    const handleRemove = () => {
        setFileList([]);
        setPreviewImage("");
    };

    useEffect(() => {
        const getAttendees = async () => {
            try {
                const response = await eventApi.getEventAttendeesByEvent(eventId);
                setAttendees(response);
            } catch (error) {
                console.log("Error fetching event attendees: ", error);
            }
        };

        const getEventBookmarks = async () => {
            try {
                const response = await eventApi.getEventBookmarks();
                setEventBookmarks(response);
            } catch (error) {
                console.log("Error fetching event bookmarks: ", error);
            }
        };

        if (eventId) {
            getAttendees();
            getEventBookmarks();
        }
    }, [eventId]);

    useEffect(() => {
        const getAllEvents = async () => {
            try {
                const response = await eventApi.getEvents();
                setAllEvents(response);
            } catch (error) {
                console.log("Error fetching all events: ", error);
            }
        };

        getAllEvents();
    }, []);

    const validateDates = () => {
        const now = new Date();
        
        if (!startDate || !endDate) {
            notification.error({
                message: 'Invalid Dates',
                description: 'Both start and end dates must be set.',
            });
            return false;
        }

        if (startDate >= endDate) {
            notification.error({
                message: 'Invalid Dates',
                description: 'Start date must be before end date.',
            });
            return false;
        }

        if (startDate < now) {
            notification.error({
                message: 'Invalid Start Date',
                description: 'Start date cannot be in the past.',
            });
            return false;
        }

        const twoYearsFromNow = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());
        if (startDate > twoYearsFromNow) {
            notification.warning({
                message: 'Far Future Date',
                description: 'The event start date is more than 2 years in the future. Are you sure this is correct?',
            });
        }

        return true;
    };

    const validateFields = () => {
        const validDates = validateDates();
        if (!validDates) return false;

        const requiredFields = [
            { name: 'Event Name', value: eventName },
            { name: 'Event Location', value: eventLocation },
            { name: 'Start Date', value: startDate },
            { name: 'End Date', value: endDate },
            { name: 'Latitude', value: latitude },
            { name: 'Longitude', value: longitude },
            { name: 'Event Image', value: previewImage },
            { name: 'Event Description', value: description },
        ];

        const missingFields = requiredFields.filter(field => !field.value);
        if (missingFields.length > 0) {
            const missingFieldNames = missingFields.map(field => field.name).join(', ');
            notification.error({
                message: 'Missing Required Fields',
                description: `Please fill in the following required fields: ${missingFieldNames}`,
            });
            return false;
        }
        return true;
    };

    const handleCloseEventView = () => {
        setDisplayEventView(false);
    };

    const checkForDuplicateEvent = (eventName, description, eventLocation) => {
        const normalizeString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        return allEvents.some(event => 
            normalizeString(event.eventName) === normalizeString(eventName) || 
            normalizeString(event.description) === normalizeString(description) ||
            normalizeString(event.eventLocation) === normalizeString(eventLocation)
        );
    };

    const handlePostEvent = async () => {
        if (!validateFields()) return;

        const isDuplicate = checkForDuplicateEvent(eventName, description, eventLocation);

        if (isDuplicate) {
            notification.error({
                message: 'Duplicate Event',
                description: 'An event with the same name, description, or location already exists.',
            });
            return;
        }

        const updatedEventData = {
            eventName,
            eventLocation,
            description,
            startDate: Math.floor(startDate.getTime() / 1000),
            endDate: Math.floor(endDate.getTime() / 1000),
            latitude,
            longitude,
            eventStatus: true,
            streamActivityId: "string",
            eventImage: previewImage
        };

        try {
            const response = await eventApi.createEvent(updatedEventData);

            if (response || response.data !== null) {
                notification.success({
                    message: 'Success',
                    description: 'Event created successfully!',
                });
                setTimeout(() => {
                    handleCloseEventView();
                }, 4000);
            } else {
                notification.error({
                    message: 'Failure',
                    description: 'Failed to create event.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while creating the event.',
            });
        }
    };

    const handleEditEvent = async () => {
        if (!validateFields()) return;
        console.log("validateFields: ", validateFields())

        const isDuplicate = checkForDuplicateEvent(eventName, description, eventLocation);

        if (isDuplicate) {
            notification.error({
                message: 'Duplicate Event',
                description: 'An event with the same name, description, or location already exists.',
            });
            return;
        }

        const updatedEventData = {
            id: eventId,
            eventName,
            eventLocation,
            description,
            startDate: Math.floor(startDate.getTime() / 1000),
            endDate: Math.floor(endDate.getTime() / 1000),
            latitude,
            longitude,
            eventStatus: true,
            streamActivityId: "string",
            eventImage: previewImage
        };

        try {
            const response = await eventApi.updateEvent(updatedEventData);
            if (response.success) {
                notification.success({
                    message: 'Success',
                    description: 'Event updated successfully!',
                });
                setTimeout(() => {
                    handleCloseEventView();
                }, 4000);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while updating the event.',
            });
        }
    };

    const toggleCancelEvent = async () => {
        let data;

        if (eventStatus) {
            data = {
                ...eventData,
                eventStatus: false,
            };
        } else {
            data = {
                ...eventData,
                eventStatus: true,
            };
        }

        try {
            const response = await eventApi.updateEvent(data);
            if (response.success) {
                if (eventStatus) {
                    notification.success({
                        message: 'Success',
                        description: 'Event cancelled successfully!',
                    });
                }else {
                    notification.success({
                        message: 'Success',
                        description: 'Event resumed successfully!',
                    });
                }
                setTimeout(() => {
                    handleCloseEventView();
                }, 4000);
            }
        } catch (error) {
            if(eventStatus) {
                notification.error({
                    message: 'Error',
                    description: 'An error occurred while cancelling the event.',
                });
            }else {
                notification.error({
                    message: 'Error',
                    description: 'An error occurred while resuming the event.',
                });
            }
        }
    };

    const handleMapClick = (e) => {
        setLatitude(e.latlng.lat);
        setLongitude(e.latlng.lng);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: handleMapClick,
        });
        return null;
    };

    const handleAddSpeaker = () => {
        setDrawerVisible(true);
    };

    const handleModalCancel = () => {
        setDrawerVisible(false);
    }

    const handleAddNewSpeaker = async (speakerDetails) => {
        const speakerData = {
            ...speakerDetails,
            eventId: eventId,
        };

        try {
            const response = await speakerApi.createSpeaker(speakerData);
            if (response) {
                notification.success({
                    message: 'Success',
                    description: 'Speaker added successfully!',
                });
                setSpeakersCount(speakersCount + 1);
                speakerDetails = {
                    profilePic: '',
                    name: '',
                    bio: '',
                    linkedInUrl: '',
                    videoLink: ''
                };
            } else {
                notification.error({
                    message: 'Failure',
                    description: 'Failed to add speaker.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while adding the speaker.',
            });
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        if (endDate && date >= endDate) {
            setEndDate(null);
            notification.warning({
                message: 'End Date Reset',
                description: 'End date has been reset. Please select a new end date after the start date.',
            });
        }
    };

    const handleEndDateChange = (date) => {
        if (date > startDate) {
            setEndDate(date);
        } else {
            notification.warning({
                message: 'Invalid Date',
                description: 'End date must be after start date.',
            });
        }
    };

    return (
        <div className="mb-4">
        <div className="flex flex-row">
            <IoMdClose
                className="cursor-pointer"
                size={18}
                onClick={handleCloseEventView}
            />
            <div className="separator-vertical" />
            <span className="text-2xl font-bold">{eventData ? "Edit Event" : "Post Event"}</span>
        </div>

        <div className="separator-horizontal" />

        <span className="text-gray-600">
            {eventData ? "Edit the details of your event" : "Create a new event by providing the necessary information below"}
        </span>

        {eventData && (
            <div className="w-1/4 h-1/4 bg-white flex flex-col left p-4">
                <span className="font-bold text-base my-1">Speakers</span>
                <span>Number of speakers added</span>
                <span className="my-2 font-bold text-xl">{speakersCount}</span>
                <Button 
                    variant="outlined"  
                    onClick={handleAddSpeaker} 
                    type="primary" 
                    className="green-border"
                >
                    Add Speaker
                </Button>
            </div>
        )}

        <div className="separator-horizontal" />

        <span className="font-bold">Event Image</span>

        <div className="separator-horizontal" />

        <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleImageChange}
            onRemove={handleRemove}
            beforeUpload={() => false}
            showUploadList={false}
        >
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {previewImage && (
                    <img
                        src={previewImage}
                        alt="Event"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            opacity: 0.7,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    />
                )}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        color: 'white',
                        fontSize: '20px',
                        backgroundColor: previewImage ? 'rgba(0, 0, 0, 0.1)' : '#2d9687',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 2,
                    }}
                >
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                </div>
            </div>
        </Upload>
        <div style={{ width: "80%" }}>
            <Input
                value={previewImage}
                onChange={(value) => setPreviewImage(value)}
                size="md"
            />
        </div>

        <div className="separator-horizontal" />

        <div style={{ width: "80%" }}>
            <span className="font-bold">Event Name</span>
            <div className="separator-horizontal" />
            <Input
                value={eventName}
                onChange={(value) => setEventName(value)}
                size="md"
            />
            <div className="separator-horizontal" />

            <span className="font-bold">Event Location</span>
            <div className="separator-horizontal" />
            <Input
                value={eventLocation}
                onChange={(value) => setEventLocation(value)}
                size="md"
            />
            <div className="separator-horizontal" />
            <span className="font-bold">Event Description</span>
            <div className="separator-horizontal" />
            <ThemeProvider theme={customTheme}>
                <TextField
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                    backgroundColor="white"
                    placeholder="Description of the purpose of the event"
                />
            </ThemeProvider>
            <div className="separator-horizontal" />

            <span className="font-bold">Event Dates</span>
            <div className="separator-horizontal" />
            <ThemeProvider theme={customTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="mb-2">
                    <span className="text-xs text-gray">
                      {startDate ? startDate.toLocaleString() : 'Not Selected'}
                    </span>
                  </div>
                  <DateTimePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMM d, yyyy h:mm aa"
                    placeholder="Select Start Date"
                    label="Start Date"
                    minDateTime={new Date()}
                  />
                </LocalizationProvider>
            </ThemeProvider>
            <div className="separator-horizontal" />

            <div className="separator-horizontal" />
            <ThemeProvider theme={customTheme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="mb-2">
                        <span className="text-xs text-gray ">{endDate ? endDate.toLocaleString() : 'Not Selected'}</span>
                    </div>
                    <DateTimePicker
                        value={endDate}
                        onChange={handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="MMM d, yyyy h:mm aa"
                        placeholder="Select End Date"
                        label="End Date"
                        minDateTime={startDate || new Date()}
                    />
                </LocalizationProvider>
            </ThemeProvider>
            <div className="separator-horizontal" />

            <span className="font-bold">Event Location (Map)</span>
            <div className="separator-horizontal" />
            
            <div style={{ height: "300px", width: "840px", marginBottom: "20px" }}>
                <MapContainer
                    center={[latitude || 51.505, longitude || -0.09]}
                    zoom={13}
                    style={{ height: "100%", width: "100%" }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <MapClickHandler />
                    {latitude && longitude && <Marker position={[latitude, longitude]} />}
                </MapContainer>
                <div className="flex bg-gray-200 h-6">
                    <div className="flex flex-row center w-full">
                        <span className="mr-4">Latitude: </span>
                        <Input 
                            value={latitude}
                            onChange={(value) => setLatitude(value)}
                            className="w-2/3 bg-gray-200 h-1"
                        />
                    </div>
                    <div className="flex flex-row center w-full">
                        <span className="ml-4 mr-4">Longitude: </span>
                        <Input 
                            value={longitude}
                            onChange={(value) => setLongitude(value)}
                            className="w-2/3 bg-gray-200 h-1"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="separator-horizontal" />

        <div className="justify-end d-flex mt-8">
            <div className="justify-end mt-3 mb-10">
                <div className="flex flex-row gap-2">
                    <ThemeProvider theme={customTheme}>
                        <Button
                            variant="contained"
                            onClick={eventData ? handleEditEvent : handlePostEvent}
                            className="green-bg"
                        >
                            {eventData ? "Update Event" : "Post Event"}
                        </Button>

                        {attendees && (
                            <Button
                                variant="outlined"
                                onClick={toggleCancelEvent}
                                className="red-border"
                            >
                                {eventStatus ? "Cancel Event" : "Resume Event"}
                            </Button>
                        )}
                    </ThemeProvider>
                </div>
            </div>
        </div>

        <SpeakerDrawer
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            handleAddNewSpeaker={handleAddNewSpeaker}
            handleModalCancel={handleModalCancel}
        />
    </div>
    );
};

export default EventDetailsTab;